import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import {
    amountFieldStyles,
    datePickerFieldStyles,
    infoFieldStyles,
    selectFieldStyles,
    textFieldStyles
} from './styles';
import { FieldLabel } from './FieldLabel';
import { FieldContainer } from './FieldContainer';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB/index';

const TextFieldComponent = ({ classes, value, onChange, label }) => (
    <FieldContainer>
        <FieldLabel className={classes.fieldLabel}>{label}</FieldLabel>
        <Grid lg={5}>
            <TextField
                variant={'outlined'}
                style={{ width: '100%', fontSize: 18 }}
                InputProps={{
                    style: { fontSize: 18 }
                }}
                value={value}
                onChange={onChange}
            />
        </Grid>
    </FieldContainer>
);

TextFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string
};

export const CustomTextField = withStyles(textFieldStyles)(TextFieldComponent);

const AmountFieldComponent = ({ classes, value, onChange, label }) => (
    <FieldContainer>
        <FieldLabel className={classes.fieldLabel}>{label}</FieldLabel>
        <Grid lg={5}>
            <TextField
                variant={'outlined'}
                style={{ width: '100%', fontSize: 18 }}
                InputProps={{
                    style: { fontSize: 18 }
                }}
                value={value}
                onChange={onChange}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
            />
        </Grid>
    </FieldContainer>
);

AmountFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string
};

export const CustomAmountField = withStyles(amountFieldStyles)(AmountFieldComponent);

const InfoFieldComponent = ({ classes, value, label }) => (
    <FieldContainer>
        <FieldLabel className={classes.fieldLabel}>{label}</FieldLabel>
        <Grid lg={5}>{value}</Grid>
    </FieldContainer>
);

InfoFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.any,
    label: PropTypes.string
};

export const CustomInfoField = withStyles(infoFieldStyles)(InfoFieldComponent);

const SelectFieldComponent = ({ classes, value, onChange, label, options }) => (
    <FieldContainer>
        <FieldLabel className={classes.fieldLabel}>{label}</FieldLabel>
        <Grid lg={5}>
            <Select variant={'outlined'} value={value} onChange={onChange} style={{ width: '100%' }}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    </FieldContainer>
);

SelectFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array
};

export const CustomSelectField = withStyles(selectFieldStyles)(SelectFieldComponent);

const DatePickerFieldComponent = ({ classes, value, onChange, label, minDate }) => (
    <FieldContainer>
        <FieldLabel className={classes.fieldLabel}>{label}</FieldLabel>
        <Grid lg={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                <DatePicker
                    variant={'outlined'}
                    autoOk
                    className={`${classes.font} ${classes.hiddenCalendarIcon}`}
                    keyboard
                    name="dateOfBirth"
                    placeholder="dd/mm/yyyy"
                    format={'dd/MM/yyyy'}
                    style={{ width: '100%' }}
                    required
                    mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                    value={value}
                    onChange={onChange}
                    minDate={minDate}
                    animateYearScrolling={false}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    </FieldContainer>
);

DatePickerFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    minDate: PropTypes.any,
    label: PropTypes.string
};

export const CustomDatePickerField = withStyles(datePickerFieldStyles)(DatePickerFieldComponent);
