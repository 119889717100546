import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import isWithinInterval from 'date-fns/isWithinInterval';
import MomentDurationFormat from 'moment-duration-format';
import { default as Moment, default as moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import * as Promise from 'bluebird';
import DOMPurify from 'dompurify';

import DrawerHeader from './DrawerHeader';
import ReduceButtons from './AppointmentReduceButtons';
import AppointmentAPI from '../../../api/appointmentApi';
import NotesApi from '../../../api/notesApi';
import AppointmentForm from './AppointmentForm';
import CancelAppointment from './CancelAppointment';
import AppointmentClipboard from './AppointmentClipboard';
import AppointmentConfirmActionModal from '../../common/AppointmentConfirmActionModal';

import { setDrawerData } from '../../../actions/dayScheduleActions';

import { ROOMS, STAFF, STAFF_WEEK } from '../../../constants/viewModes';

import { appointmentStyles as styles } from './styles';

import { fetchCustomerCourses } from '../../../actions/customerActions';

import { popupType, openPopupModal, setCancelingReason } from '../../../actions/appointmentActions';

import { APPOINTMENT_STATUS_TYPES, POPUP, TC_MERGE_TAGS, TC_MODAL_MERGE_TAGS } from '../../../collums-constants';
import { isValidMongoIdString, late_cancellation_template } from '../../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import getMomentToCalendar from '../../../services/helpers/getCalendarMoment';
import CancelContinueModal from '../../common/CancelContinueModal';
import { cloneDeep } from 'lodash';
import defaultRenderCheck from '../../../services/helpers/defaultRenderCheck';
import {
    getDraggedRescheduleApptSelector,
    getEditingAppointmentSelector,
    getIsOnCancelModeSelector,
    getRemovedApptsSelector,
    getSavedAppointmentSelector
} from '../../../customSelectors/drawer';
import {
    getAppointmentsOnClipboardSelector,
    getCurrentClinicSelector,
    getIsClipboardVisibleSelector,
    getLeftDrawerFormChangedSelector,
    getOrganisationCancellationFormReasonSelector,
    getOrganisationDeposingAmountSelector,
    getOrganisationDeposingTypeSelector,
    getOrganisationLateCancellationChargeType,
    getOrganisationLateCancellationText,
    getOrganisationLCCValueSelector,
    getOrganisationLCPeriodSelector,
    getOrganisationShowBookingDepositNotificationInCalendarSelector,
    getOrganisationShowCancelTermsSelector,
    getViewModeSelector,
    getZoomLevelSelector
} from '../../../customSelectors/calendar';
import { getAllSchedulesSelector } from '../../../customSelectors/appointments';
import { getCurrencySymbol } from '../../../collums-components/helpers';
import { differenceInHours } from 'date-fns';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import ServiceAPI from '../../../api/serviceApi';
import NumberFormat from 'react-number-format';

MomentDurationFormat(Moment);

function AppointmentContent({
    handleChange,
    handleChangeNotes,
    clinicStartHour,
    clinicEndHour,
    classes,
    searchCustomers,
    showCreateCustomerModal,
    onClose,
    onConfirm,
    onCancel,
    postingData,
    validateNewAppointment,
    selectedDay,
    setSelectedDay,
    handleTrownInvalidDateWarning,
    clinicStartTime,
    clinicEndTime,
    validateCreditCard,
    isDataLostModalOpen,
    setDataLostModalOpen
}) {
    const [renderCount, setRenderCount] = useState(1);
    const [isCancellingMode, setIsCancellingMode] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isRescheduleConfirmationModalOpen, setIsRescheduleConfirmationModalOpen] = useState(false);

    const [isAppointmentInPastModalOpen, setIsAppointmentInPastModalOpen] = useState(false);
    const [isRescheduleChargeModalOpen, setIsRescheduleChargeModalOpen] = useState(false);

    const [reduceApptsMinutes, setReduceApptsMinutes] = useState({});
    const [linkedAppointments, setLinkedAppointments] = useState([]);
    const [allDaysSelected, setAllDaysSelected] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [depositAmount, setDepositAmount] = useState(0);
    const [depositItems, setDepositItems] = useState([]);
    const depositTimerRef = useRef(null);

    const [isShowingCancellationModal, setIsShowingCancellationModal] = useState(false);
    const [lastServiceSelected, setLastServiceSelected] = useState(null);

    const dispatch = useDispatch();

    const clinic = useSelector(getCurrentClinicSelector);
    const form = useSelector(getEditingAppointmentSelector);
    const lateCancellationChargeValue = useSelector(getOrganisationLCCValueSelector);
    const lateCancellationPeriod = useSelector(getOrganisationLCPeriodSelector);
    const showCancelTerms = useSelector(getOrganisationShowCancelTermsSelector);
    const cancelingReason = useSelector(getOrganisationCancellationFormReasonSelector);
    const orgDepositAmount = useSelector(getOrganisationDeposingAmountSelector);
    const orgDepositType = useSelector(getOrganisationDeposingTypeSelector);
    const showBookingDepositNotificationInCalendar = useSelector(
        getOrganisationShowBookingDepositNotificationInCalendarSelector
    );
    const lateCancellationText = useSelector(getOrganisationLateCancellationText);
    const lateCancellationType = useSelector(getOrganisationLateCancellationChargeType);
    const schedule = useSelector(getAllSchedulesSelector);
    const isOnCancelMode = useSelector(getIsOnCancelModeSelector);
    const draggedRescheduleAppt = useSelector(getDraggedRescheduleApptSelector);
    const isClipboardVisible = useSelector(getIsClipboardVisibleSelector);
    const viewMode = useSelector(getViewModeSelector);
    const editingAppointment = useSelector(getEditingAppointmentSelector);
    const savedAppointment = useSelector(getSavedAppointmentSelector);
    const zoom = useSelector(getZoomLevelSelector);
    const removedAppointments = useSelector(getRemovedApptsSelector);
    const leftDrawerFormChanged = useSelector(getLeftDrawerFormChangedSelector);
    const appointmentsOnClipboard = useSelector(getAppointmentsOnClipboardSelector);

    const reduceButtonsRef = useRef();
    const apptPanelRef = useRef();
    const oldElScroll = useRef({
        hasChangeLinkedAppts: false,
        maxOldScrollValue: 0
    });

    useEffect(() => {
        setIsCancellingMode(isOnCancelMode);
    }, [isOnCancelMode]);

    useEffect(() => {
        oldElScroll.current.hasChangeLinkedAppts = true;
        setLinkedAppointments(form.linkedAppointments || []);
        //eslint-disable-next-line
    }, [form.linkedAppointments]);

    useEffect(() => {
        if (depositTimerRef.current) {
            clearTimeout(depositTimerRef.current);
        }

        depositTimerRef.current = setTimeout(() => {
            const servicesDeposits = [];
            let selectedServices = [];
            const mainService = allServices.find(srv => srv.id === form.service.id);
            if (mainService) {
                mainService.currentPrice = form.price || (form.service && +form.service.grossPrice) || 0;
                if (mainService.currentPrice) {
                    selectedServices.push(mainService);
                }
            }

            if (form.linkedAppointments) {
                // eslint-disable-next-line array-callback-return
                form.linkedAppointments.map(app => {
                    const linkedService = allServices.find(srv => srv.id === app.service.id);
                    if (linkedService) {
                        linkedService.currentPrice =
                            Number(app.price) || (app.service && Number(app.service.grossPrice)) || 0;
                        selectedServices.push(linkedService);
                    }
                });
            }

            // eslint-disable-next-line array-callback-return
            selectedServices.map(srv => {
                let depositType = orgDepositType;
                let depositAmount = orgDepositAmount;
                let depositValue = 0;
                if (orgDepositType && srv.category.bookingDepositAmount) {
                    depositType = srv.category.bookingDepositType;
                    depositAmount = srv.category.bookingDepositAmount;
                }
                if (orgDepositType && srv.bookingDepositAmount) {
                    depositType = srv.bookingDepositType;
                    depositAmount = srv.bookingDepositAmount;
                }

                // if (srv.category.bookingDepositType === 'No deposit' || srv.bookingDepositType === 'No deposit') {
                if (srv.bookingDepositType === 'No deposit') {
                    depositType = 'No deposit';
                }

                if (depositType === 'Percentage') {
                    depositValue = (srv.currentPrice * depositAmount) / 100;
                }
                if (depositType === 'Fixed amount') {
                    depositValue = depositAmount;
                }
                if (depositValue) {
                    servicesDeposits.push({ name: srv.name, value: depositValue });
                }
            });

            const depositTotal = servicesDeposits.reduce((accumulator, currentObject) => {
                return accumulator + currentObject.value;
            }, 0);

            setDepositItems(servicesDeposits);

            if (showBookingDepositNotificationInCalendar && depositAmount !== depositTotal && servicesDeposits.length) {
                if (!editingAppointment?.id) {
                    setIsDepositModalOpen(true);
                } else {
                    if (lastServiceSelected && lastServiceSelected !== form?.service?.id) {
                        setIsDepositModalOpen(true);
                    }
                    setLastServiceSelected(form?.service?.id);
                }
                setDepositAmount(depositTotal);
            }

            depositTimerRef.current = null;
        }, 1000);

        return () => {
            if (depositTimerRef.current) {
                clearTimeout(depositTimerRef.current);
            }
        };
    }, [
        form.linkedAppointments,
        form.practitioner,
        form.service,
        allServices,
        form,
        depositAmount,
        orgDepositType,
        orgDepositAmount,
        showBookingDepositNotificationInCalendar,
        editingAppointment,
        lastServiceSelected
    ]);

    useLayoutEffect(() => {
        if (draggedRescheduleAppt && apptPanelRef.current && oldElScroll.current.hasChangeLinkedAppts) {
            oldElScroll.current.hasChangeLinkedAppts = false;
            apptPanelRef.current.scrollTop = apptPanelRef.current.scrollHeight * oldElScroll.current.scrollPercentage;
            dispatch(
                setDrawerData({
                    draggedRescheduleAppt: undefined
                })
            );
        }
        return () => {
            if (apptPanelRef.current) {
                oldElScroll.current = {
                    ...oldElScroll.current,
                    //eslint-disable-next-line
                    scrollPercentage: apptPanelRef.current.scrollTop / apptPanelRef.current.scrollHeight
                };
            }
        };
    }, [draggedRescheduleAppt, apptPanelRef, appointmentsOnClipboard, linkedAppointments, dispatch]);

    useEffect(() => {
        (async () => {
            const allServices = await ServiceAPI.searchForServiceByName('', 9999, null, null, null, null, false, false);
            setAllServices(allServices);
            if (form) {
                setLinkedAppointments(form.linkedAppointments || []);
                const highlightedTimeslots = [];

                if (!form.service) {
                    highlightedTimeslots.push(form);
                }

                if (form.linkedAppointments?.length) {
                    highlightedTimeslots.push(
                        ...form.linkedAppointments
                            .filter(linked => !linked.service)
                            .map(linked => {
                                if (linked.service) return linked;
                                return {
                                    ...linked,
                                    event: {
                                        start: linked.event.start,
                                        end: linked.event.start.clone().add(60 / zoom, 'minutes')
                                    },
                                    duration: 60 / zoom
                                };
                            })
                    );
                }
                dispatch(
                    setDrawerData({
                        highlightedTimeslots
                    })
                );
            }
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            if (form.id) {
                const clinic = localStorage.getItem('currentClinic');
                const notesList = await NotesApi.getNotesByCustomerId(form.customer.id, clinic, true);

                dispatch(popupType([POPUP.TYPES.BOOKING, { appt: form.id }]));
                dispatch(openPopupModal(notesList));
            }
        })();
        /* eslint-disable-next-line */
    }, [dispatch]);

    const isAddApptBtnDisabled = useMemo(() => {
        if (form.status === APPOINTMENT_STATUS_TYPES.COMPLETED) {
            return true;
        }
        const lastAppt = linkedAppointments.length ? linkedAppointments[linkedAppointments.length - 1] : form;

        const expectedApptEvent = {
            start: new Date(
                lastAppt.event.end
                    .clone()
                    .set({ seconds: 0 })
                    .subtract(1, 'second') // subtract to include on the compare function4
            ),
            end: new Date(
                lastAppt.event.end
                    .clone()
                    .add(1, 'minutes')
                    .set({ seconds: 0 })
                    .subtract(1, 'second')
            )
        };
        let practitionerSchedule;
        if (STAFF === viewMode) {
            practitionerSchedule = schedule.find(
                sc =>
                    lastAppt.practitioners &&
                    lastAppt.practitioners
                        .map(practitioner => practitioner && practitioner.id)
                        .includes(sc.practitioner && sc.practitioner.id)
            );
        } else if (STAFF_WEEK === viewMode) {
            practitionerSchedule = schedule.find(sc => sc.date && sc.date.isSame(form.event.start, 'day'));
        } else if (ROOMS === viewMode) {
            practitionerSchedule = schedule;
        }
        if (!practitionerSchedule) return true;
        let isInAvailableHours;
        if (ROOMS === viewMode) {
            const startMoment = getMomentToCalendar(clinicStartHour);
            const endMoment = getMomentToCalendar(clinicEndHour);
            const clinicStart = startMoment.toDate();
            const clinicEnd = endMoment.toDate();
            isInAvailableHours =
                isWithinInterval(expectedApptEvent.start, { start: clinicStart, end: clinicEnd }) &&
                isWithinInterval(expectedApptEvent.end, { start: clinicStart, end: clinicEnd });
        } else {
            isInAvailableHours = practitionerSchedule.practitionerSchedules.some(schedule => {
                const scheduleStart = new Date(
                    moment(schedule.start, 'HH:mm')
                        .set({ date: schedule.date.date() })
                        .format()
                );
                const scheduleEnd = new Date(
                    moment(schedule.end, 'HH:mm')
                        .set({ date: schedule.date.date() })
                        .format()
                );
                if (!moment(scheduleStart).isSame(expectedApptEvent.start, 'days')) {
                    return true;
                }
                return (
                    isWithinInterval(
                        expectedApptEvent.start,
                        { start: scheduleStart, end: scheduleEnd },
                        { additionalDigits: 0 }
                    ) &&
                    isWithinInterval(
                        expectedApptEvent.end,
                        { start: scheduleStart, end: scheduleEnd },
                        { additionalDigits: 0 }
                    )
                );
            });
        }

        if (!isInAvailableHours) return true;
        let appointments = practitionerSchedule.appointments;
        if (ROOMS === viewMode) {
            appointments = practitionerSchedule
                .map(sc => sc.appointments)
                .flat()
                .filter(app => app.room && form.room && app.room.id === form.room.id);
        }
        const isOverlapingWithOtherAppointments = appointments.some(app => {
            const scheduleStart = new Date(moment(app.event.start, 'HH:mm').format());
            const scheduleEnd = new Date(moment(app.event.end, 'HH:mm').format());
            const isDatesOverllaping = areIntervalsOverlapping(
                { start: scheduleStart, end: scheduleEnd },
                expectedApptEvent
            );
            const timeToNextAppointment = moment(scheduleStart).diff(expectedApptEvent.start, 'minutes');
            const isLessThan10minToNextAppointment =
                timeToNextAppointment > 0 && timeToNextAppointment < 10 ? true : false;

            return (!lastAppt.id || app.id !== lastAppt.id) && isDatesOverllaping && isLessThan10minToNextAppointment;
        });

        if (isOverlapingWithOtherAppointments) return true;
        return false;
    }, [clinicEndHour, clinicStartHour, form, linkedAppointments, schedule, viewMode]);

    const addAppointment = latestAppointment => {
        const newLinkedAppointment = {
            customer: latestAppointment.customer,
            practitioners: latestAppointment.practitioners,
            room: '',
            event: {
                start: latestAppointment.event.end.clone(),
                end: latestAppointment.event.end.clone().add(5, 'minutes')
                // ! .subtract(reduceApptsMinutes[latestAppointment.id] || 0, 'minutes')
                // ? REMOVED TEMPORARY DUE TO
                // ? LOGIC FOR NEW LINKEDS TO START WITH
                // ? 5 MIN
            },
            type: latestAppointment.type,
            isLinked: latestAppointment.isLinked,
            isAddedWithOriginal: true,
            id: Math.random() * 100,
            service: 'Search for service',
            notification: latestAppointment.notification
        };
        const allLinkedAppointments = [...linkedAppointments, newLinkedAppointment];
        setLinkedAppointments(allLinkedAppointments);
        handleChange({ linkedAppointments: allLinkedAppointments }).then(() => {
            setTimeout(() => {
                if (reduceButtonsRef.current) {
                    scrollToRef(reduceButtonsRef);
                }
            }, 100);
        });
    };

    const scrollToRef = r => {
        r.current.scrollIntoView({ behavior: 'smooth' });
    };

    const changeLinkedApptCheckbox = (appointment, value, index) => {
        const linkedAppts = [...linkedAppointments];
        appointment.isUnlinked = !value && appointment?.isLinked !== value ? true : undefined;
        appointment.isLinked = value;
        linkedAppts.splice(index, 1, appointment);

        dispatch(
            setDrawerData({
                editingAppointment: { ...editingAppointment, islinkedAppt: value }
            })
        );

        handleChange({ linkedAppointments: linkedAppts });

        // update clipboard
        if (isClipboardVisible) {
            if (!value) {
                handleUnlinkAppt(appointment, false, editingAppointment);
            } else {
                let copiedClipboard = [...appointmentsOnClipboard].filter(el => el?.id !== appointment?.id);

                const originalAppointmentIndex = copiedClipboard.findIndex(el => el?.id === editingAppointment?.id);

                if (originalAppointmentIndex !== -1) {
                    copiedClipboard[originalAppointmentIndex].linkedAppointments = linkedAppts;
                }

                dispatch(
                    setDrawerData({
                        appointmentsOnClipboard: copiedClipboard
                    })
                );
            }
        }
    };

    const handleLinkedAppointmentChange = useCallback(
        (form, index, isUpdatingNotes = false) => {
            const linkedAppts = [...linkedAppointments];
            const newAppt = { ...linkedAppts[index], ...form };
            linkedAppts.splice(index, 1, newAppt);
            setLinkedAppointments(linkedAppts);
            handleChange({ linkedAppointments: linkedAppts }, isUpdatingNotes);
        },
        [handleChange, linkedAppointments]
    );

    const removeLinkedAppt = index => {
        let linkedAppts = [...linkedAppointments];
        let linkedId = linkedAppointments[index].id;
        const removedAppts = linkedAppts.splice(index, 1);
        const removedDuration = getDuration(removedAppts[0]);

        linkedAppts = linkedAppts.map((linkedAppt, linkedIndex) => {
            if (linkedIndex < index) return linkedAppt;
            const newStart = linkedAppt.event.start.clone().subtract(removedDuration, 'minutes');
            const newEnd = linkedAppt.event.end.clone().subtract(removedDuration, 'minutes');

            return {
                ...linkedAppt,
                event: {
                    start: newStart,
                    end: newEnd
                }
            };
        });

        // Add the removed appointment ID into the removedAppointments
        let apptsRemoved = [...(removedAppointments || []), linkedId];

        dispatch(
            setDrawerData({
                removedAppointments: apptsRemoved
            })
        );

        setLinkedAppointments(linkedAppts);
        handleChange({ linkedAppointments: linkedAppts });
    };

    const getDuration = appt => {
        return appt.event.end.diff(appt.event.start, 'minutes');
    };

    const handleReduceMinutesChanges = (appointment, newReduceMinutes, index, changeCheckbox = true) => {
        if (changeCheckbox) setReduceApptsMinutes({ [appointment.id]: newReduceMinutes });
        reduceMinutesToLinkedAppts(appointment, newReduceMinutes, index);
    };

    const reduceMinutesToLinkedAppts = useCallback(
        (appointment, minutesToReduce, index) => {
            dispatch(
                setDrawerData({
                    editingAppointment: { ...editingAppointment, changedDuration: minutesToReduce }
                })
            );
            const currentDuration = appointment.currentDuration || getDuration(appointment);
            if (currentDuration <= minutesToReduce) return;
            appointment.currentDuration = currentDuration;
            const newEnd = appointment.event.start
                .clone()
                .add(currentDuration, 'minutes')
                .subtract(minutesToReduce, 'minutes');
            appointment.event.end = newEnd;

            let linkedAppts = [...linkedAppointments];
            linkedAppts.splice(index, 1, appointment);
            linkedAppts = linkedAppts.map((linkedAppt, linkedIndex) => {
                // Make sure it will just change the appointments after the current one
                if (linkedIndex <= index) return linkedAppt;
                const newStart = linkedAppts[linkedIndex - 1].event.end.clone();
                const currentDuration = linkedAppt.currentDuration || getDuration(linkedAppt);
                const newEnd = newStart.clone().add(currentDuration, 'minutes');
                linkedAppt.currentDuration = currentDuration;
                return {
                    ...linkedAppt,
                    event: {
                        start: newStart,
                        end: newEnd
                    }
                };
            }, []);
            setLinkedAppointments(linkedAppts);
            handleChange({ linkedAppointments: linkedAppts });
        },
        [handleChange, linkedAppointments, dispatch, editingAppointment]
    );

    useEffect(() => {
        if (form.customer) {
            // ignore form change when loading customer
            onChangeCustomer(form.customer, true);
        }
        //eslint-disable-next-line
    }, []);

    const onChangeCustomer = useCallback(
        async (customer, ignoreChange = false) => {
            const linkedAppt = [...(editingAppointment?.linkedAppointments || [])];
            if (!ignoreChange) {
                dispatch(fetchCustomerCourses(customer.id, clinic.id, true));
            }

            handleChange({
                linkedAppointments: linkedAppt.map(app => ({ ...app, customer })),
                customer,
                ignoreChange
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clinic.id, dispatch, editingAppointment?.linkedAppointments, handleChange]
    );

    const cancelAppointments = async (appointmentsToCancel, cancellationOptions, course, unlink = false) => {
        try {
            if (isCancellingMode) {
                await Promise.each(
                    appointmentsToCancel,
                    async appt =>
                        await AppointmentAPI.remove({ id: appt }, undefined, cancellationOptions, course, unlink)
                );
                onCancel();
                setIsCancellingMode(false);
            } else {
                setIsCancellingMode(true);
            }
        } catch (e) {
            console.error(e);
            if (e.data?.error) toastr.error(e.data.message);
            else toastr.error('Something went wrong (code: c0018)');
            onCancel();
            setIsCancellingMode(false);
        }
    };

    const cancelModal = () => {
        setIsCancelModalOpen(false);
    };

    const selectLastAppointment = useCallback(
        appt => {
            if (!form.service || !form.linkedAppointments || !form.linkedAppointments.length) {
                handleChange({ service: appt.service, price: appt.service.grossPrice });
                return;
            }
            const index = linkedAppointments.findIndex(appt => !appt.service);
            handleLinkedAppointmentChange({ service: appt.service, price: appt.service.grossPrice }, index);
        },
        [form.linkedAppointments, form.service, handleChange, handleLinkedAppointmentChange, linkedAppointments]
    );

    const selectLastAppointmentLinked = useCallback(
        (appt, data) => {
            let index = linkedAppointments.findIndex(appt => appt.service === 'Search for service');

            if (index === -1) {
                index = linkedAppointments.length - 1;
            }
            if (index !== -1) {
                handleLinkedAppointmentChange(data, index);
            }
        },
        [handleLinkedAppointmentChange, linkedAppointments]
    );

    const getTotalPrice = () => {
        return (
            (+form.price || (form.service && +form.service.grossPrice) || 0) +
            linkedAppointments.reduce(
                (sum, app) => (sum += Number(app.price) || (app.service && Number(app.service.grossPrice)) || 0),
                0
            )
        );
    };

    const handleDurationChange = useCallback(
        (event, index) => {
            let linkedAppts = [...linkedAppointments];

            if (index !== null) {
                linkedAppts = linkedAppts.map((el, ind) => {
                    if (index === ind) {
                        return { ...el, ...event };
                    }
                    return el;
                });
                linkedAppts = linkedAppts.reduce((arr, linkedAppt, linkedIndex) => {
                    const lastAppt = arr[linkedIndex - 1];
                    const canChange = (() => {
                        if (!lastAppt) return false;
                        const start = lastAppt.event.start.toDate().getTime();
                        const end = lastAppt.event.end.toDate().getTime();
                        const currApptStart = linkedAppt.event.start.toDate().getTime();
                        const currApptEnd = linkedAppt.event.end.toDate().getTime();
                        return (
                            (start > currApptStart && start < currApptEnd) ||
                            (end > currApptStart && end < currApptEnd) ||
                            (start <= currApptStart && end >= currApptEnd)
                        );
                    })();
                    if (!canChange) {
                        arr.push(linkedAppt);
                        return arr;
                    }
                    const newStart = !linkedIndex
                        ? lastAppt.event.start.clone().add(event.duration, 'minutes')
                        : lastAppt.event.end.clone();
                    const currentDuration = getDuration(linkedAppt);
                    const newEnd = newStart.clone().add(currentDuration, 'minutes');
                    linkedAppt.currentDuration = currentDuration;
                    arr.push({
                        ...linkedAppt,
                        event: {
                            start: newStart,
                            end: newEnd
                        }
                    });

                    return arr;
                }, []);

                handleChange({ linkedAppointments: linkedAppts });
                return;
            }

            linkedAppts = linkedAppts.reduce((arr, linkedAppt, linkedIndex) => {
                const lastAppt = linkedIndex ? arr[linkedIndex - 1] : event;
                const canChange = (() => {
                    if (!lastAppt) return false;
                    const start = lastAppt.event.start.toDate().getTime();
                    const end = lastAppt.event.end.toDate().getTime();
                    const currApptStart = linkedAppt.event.start.toDate().getTime();
                    const currApptEnd = linkedAppt.event.end.toDate().getTime();
                    return (
                        (start > currApptStart && start < currApptEnd) ||
                        (end > currApptStart && end < currApptEnd) ||
                        (start <= currApptStart && end >= currApptEnd)
                    );
                })();
                if (!canChange) {
                    arr.push(linkedAppt);
                    return arr;
                }
                const newStart = !linkedIndex
                    ? lastAppt.event.start.clone().add(event.duration, 'minutes')
                    : lastAppt.event.end.clone();
                const currentDuration = getDuration(linkedAppt);
                const newEnd = newStart.clone().add(currentDuration, 'minutes');
                linkedAppt.currentDuration = currentDuration;
                arr.push({
                    ...linkedAppt,
                    event: {
                        start: newStart,
                        end: newEnd
                    }
                });

                return arr;
            }, []);

            if (index !== null) {
                linkedAppts.splice(index, 1, { ...linkedAppts[index], ...event });
                handleChange({ linkedAppointments: [...linkedAppts] });
                return;
            }
            handleChange({ ...event, linkedAppointments: [...linkedAppts] });
        },
        [handleChange, linkedAppointments]
    );

    const handleStartChange = useCallback(
        (data, index) => {
            let linkedAppts = [...linkedAppointments];

            // First appt of linkeds
            if (index === null) {
                handleChange({ ...data, linkedAppointments: [...linkedAppts] });
                return;
            }
            // Difference in minutes of original time and the new one
            linkedAppts = linkedAppts.map((el, ind) => {
                if (ind === index) return { ...el, ...data };
                return el;
            });

            // Update the form
            handleChange({ linkedAppointments: linkedAppts });
        },
        [handleChange, linkedAppointments]
    );

    const renderLinkedAppts = () => {
        if (isCancellingMode) {
            return null;
        }
        return (
            <>
                {linkedAppointments.map((linkedAppointment, index) => {
                    return (
                        <React.Fragment key={`linked-appointment-${linkedAppointment.id}-${index}`}>
                            <div ref={reduceButtonsRef}>
                                <ReduceButtons
                                    editingAppointment={form}
                                    appointment={linkedAppointment}
                                    index={index}
                                    reduceApptsMinutes={reduceApptsMinutes}
                                    setReduceApptsMinutes={setReduceApptsMinutes}
                                    handleReduceMinutesChanges={handleReduceMinutesChanges}
                                    changeLinkedApptCheckbox={changeLinkedApptCheckbox}
                                />
                            </div>
                            <AppointmentForm
                                isCancellingMode={isCancellingMode}
                                form={{ ...linkedAppointment, parentForm: form }}
                                handleChange={form => handleLinkedAppointmentChange(form, index)}
                                handleChangeNotes={notes => handleLinkedAppointmentChange({ notes }, index, true)}
                                searchCustomers={searchCustomers}
                                showCreateCustomerModal={showCreateCustomerModal}
                                onRemove={() => removeLinkedAppt(index)}
                                isAggregatedAppointment
                                clinicStartHour={clinicStartHour}
                                clinicEndHour={clinicEndHour}
                                clinicStartTime={clinicStartTime}
                                clinicEndTime={clinicEndTime}
                                totalPrice={index === linkedAppointments.length - 1 ? getTotalPrice() : null}
                                changeDuration={event => handleDurationChange(event, index)}
                                changeStart={event => handleStartChange(event, index)}
                                appointmentLhdIndex={index + 1}
                                validateCreditCard={validateCreditCard}
                            />
                        </React.Fragment>
                    );
                })}
                {!isAddApptBtnDisabled && (
                    <Box p={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            id="lhd-add-another-service-btn"
                            data-cy="lhd-add-another-service-btn"
                            disabled={
                                linkedAppointments.length
                                    ? linkedAppointments[linkedAppointments.length - 1].service ===
                                          'Search for service' || !form.customer
                                    : form.service === 'Search for service' || !form.customer
                            }
                            className={classes.blueBtn}
                            onClick={() => {
                                addAppointment(
                                    linkedAppointments.length ? linkedAppointments[linkedAppointments.length - 1] : form
                                );
                            }}
                        >
                            <Typography className={classes.btnText}>Add another service</Typography>
                        </Button>
                    </Box>
                )}
            </>
        );
    };

    const handleUnlinkAppt = (linkedAppt, stateCheck, appointment) => {
        // if it's linked unlink it from original appt and vice versa
        let originalAppt = { ...appointment };
        if (!stateCheck)
            originalAppt.linkedAppointments = appointment.linkedAppointments.filter(el => el.id !== linkedAppt.id);
        else originalAppt.linkedAppointments.push(linkedAppt);

        // If the current appt is linked, unlink it and vice versa
        let apptToUnlink = { ...linkedAppt };

        if (!stateCheck) {
            apptToUnlink.isLinked = false;
            apptToUnlink.isUnlinked = true;
            apptToUnlink.linkedAppointments = [];
            apptToUnlink.notification = { email: false, sms: false };
            apptToUnlink.repeatsEndCondition = originalAppt.repeatsEndCondition;
            apptToUnlink.repeatsLastOccurrence = originalAppt.repeatsLastOccurrence;
            apptToUnlink.repeatsOccurrences = originalAppt.repeatsOccurrences;
        } else {
            apptToUnlink.isLinked = true;
            delete apptToUnlink.linkedAppointments;
            delete apptToUnlink.notification;
            delete apptToUnlink.repeatsEndCondition;
            delete apptToUnlink.repeatsLastOccurrence;
            delete apptToUnlink.repeatsOccurrences;
        }

        const apptsOnClipboard = [
            ...appointmentsOnClipboard.filter(el => el.id !== originalAppt.id && el.id !== apptToUnlink.id),
            originalAppt,
            apptToUnlink
        ];

        const newEditingAppt = cloneDeep(editingAppointment);

        if (newEditingAppt.linkedAppointments) {
            newEditingAppt.linkedAppointments = newEditingAppt.linkedAppointments.map(appt => {
                if (apptToUnlink.id === appt.id)
                    return {
                        ...appt,
                        isLinked: false,
                        isUnlinked: true
                    };
                return appt;
            });
        }

        dispatch(
            setDrawerData({
                editingAppointment: newEditingAppt,
                appointmentsOnClipboard: apptsOnClipboard
            })
        );
    };

    const renderClipboard = () => {
        return (
            <AppointmentClipboard
                handleUnlinkAppt={handleUnlinkAppt}
                isClipboardVisible={isClipboardVisible}
                form={form}
                clinic={clinic}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                clinicStartHour={clinicStartHour}
                clinicEndHour={clinicEndHour}
                zoom={zoom}
                viewMode={viewMode}
                allDaysSelected={allDaysSelected}
                setAllDaysSelected={setAllDaysSelected}
            />
        );
    };

    const renderCancelOrCloseButton = useMemo(
        () => (
            <Button
                className={classNames(classes.regularButton, classes.cancelButton)}
                onClick={() => {
                    if (!isDataLostModalOpen) {
                        if (leftDrawerFormChanged) {
                            setDataLostModalOpen(true);
                        } else {
                            const appointment = appointmentsOnClipboard[0];
                            if (appointment) {
                                if (!selectedDay.isSame(appointment.event.start, 'day')) {
                                    setSelectedDay(appointment.event.start);
                                }
                            }
                            dispatch(
                                setDrawerData({
                                    isDrawerOpen: false,
                                    drawerAnimation: false,
                                    isClipboardVisible: false,
                                    highlightedTimeslots: [],
                                    editingAppointment: null,
                                    appointmentsOnClipboard: []
                                })
                            );
                        }
                    }
                }}
                variant="outlined"
                disabled={postingData}
            >
                <Typography className={classes.textButton}>
                    {form.id && isClipboardVisible ? 'Cancel' : 'Close'}
                </Typography>
            </Button>
        ),
        [
            classes.regularButton,
            classes.cancelButton,
            classes.textButton,
            dispatch,
            appointmentsOnClipboard,
            setSelectedDay,
            selectedDay,
            postingData,
            form.id,
            isClipboardVisible,
            isDataLostModalOpen,
            leftDrawerFormChanged,
            setDataLostModalOpen
        ]
    );
    const formStart = form.event.start;

    useEffect(() => {
        const today = selectedDay.isSame(formStart, 'day');
        if (!today) {
            if (form.isReescheduled && !isClipboardVisible) {
                // Unselecting appointment when changing day after rescheduling without saving
                dispatch(
                    setDrawerData({
                        isDrawerOpen: false,
                        drawerAnimation: false,
                        isClipboardVisible: false,
                        highlightedTimeslots: [],
                        editingAppointment: null,
                        appointmentsOnClipboard: []
                    })
                );
            }
        }
        //eslint-disable-next-line
    }, [form.isReescheduled, selectedDay, formStart, dispatch, isClipboardVisible, form.id]);

    const renderRescheduleButton = useMemo(
        () => (
            <Button
                id="appointment-lhd-reschedule-button"
                className={classes.regularButton}
                onClick={() => {
                    setIsRescheduleConfirmationModalOpen(true);
                }}
            >
                <Typography className={classes.regularButtonText}>Reschedule</Typography>
            </Button>
        ),
        [classes.regularButton, classes.regularButtonText]
    );
    const renderBackButton = useMemo(
        () => (
            <Button
                className={classNames(classes.regularButton, classes.cancelButton)}
                onClick={async () => {
                    const drawerDataToUpdate = {};
                    const appointment = isClipboardVisible && savedAppointment ? savedAppointment : editingAppointment;
                    if (isClipboardVisible) {
                        Object.assign(drawerDataToUpdate, {
                            isClipboardVisible: false,
                            editingAppointment: appointment,
                            savedAppointment: null
                        });
                    }
                    setAllDaysSelected(false);

                    if (!selectedDay.isSame(appointment?.event?.start, 'day')) {
                        setSelectedDay(appointment?.event?.start);
                    }
                    dispatch(setCancelingReason(undefined));
                    setIsCancellingMode(false);
                    if (Object.keys(drawerDataToUpdate).length) {
                        dispatch(
                            setDrawerData({
                                isClipboardVisible: false,
                                editingAppointment: savedAppointment,
                                savedAppointment: null
                            })
                        );
                    }
                }}
                variant="outlined"
                disabled={postingData}
            >
                <Typography className={classes.textButton}>Back</Typography>
            </Button>
        ),
        [
            savedAppointment,
            classes.regularButton,
            classes.cancelButton,
            classes.textButton,
            postingData,
            selectedDay,
            dispatch,
            setSelectedDay,
            editingAppointment,
            isClipboardVisible
        ]
    );

    const renderCancelButton = useMemo(
        () => (
            <Button
                className={classes.regularButton}
                onClick={() => {
                    setIsShowingCancellationModal(true);
                    if (
                        editingAppointment.linkedAppointments.length &&
                        editingAppointment.linkedAppointments.some(el => !isValidMongoIdString(el.id))
                    ) {
                        dispatch(
                            setDrawerData({
                                editingAppointment: {
                                    ...editingAppointment,
                                    linkedAppointments: editingAppointment.linkedAppointments.filter(linkedAppt =>
                                        isValidMongoIdString(linkedAppt.id)
                                    )
                                }
                            })
                        );
                    }
                }}
            >
                <Typography className={classes.regularButtonText}>Cancel appt</Typography>
            </Button>
        ),
        [classes.regularButton, classes.regularButtonText, editingAppointment, dispatch]
    );

    const cancellationChargeModal = useMemo(() => {
        if (isShowingCancellationModal) {
            return (
                <AppointmentConfirmActionModal
                    setIsOpen={() => setIsShowingCancellationModal(false)}
                    actionOnContinue={() => setIsCancellingMode(true)}
                    lateCancellationCharge={lateCancellationChargeValue}
                    lateCancellationPeriod={lateCancellationPeriod}
                    showCancelTerms={showCancelTerms}
                    appointmentStartTime={form.event.start}
                    type="Cancel"
                />
            );
        }
        return <></>;
    }, [isShowingCancellationModal, form, lateCancellationChargeValue, lateCancellationPeriod, showCancelTerms]);

    const shouldOpenRescheduleChargeModal = useCallback(async () => {
        const appointment = isClipboardVisible && savedAppointment ? savedAppointment : editingAppointment;
        if (!appointment?.id) {
            return false; // new appointment
        }

        const appointmentData = await AppointmentAPI.findById(appointment.id);

        if (appointmentData && lateCancellationPeriod) {
            const hoursToAppointment = () =>
                differenceInHours(
                    getCurrentTimezonedDate(appointmentData.event.start.toDate()),
                    getCurrentTimezonedDate()
                );

            return form.isReescheduled && hoursToAppointment() < lateCancellationPeriod;
        }

        return false;
    }, [isClipboardVisible, savedAppointment, editingAppointment, form.isReescheduled, lateCancellationPeriod]);

    const renderSaveButton = useMemo(() => {
        return (
            <Button
                id="appointment-lhd-save-button"
                data-cy="appt-lhd-save-button"
                className={classNames(classes.regularButton, classes.saveButton)}
                tabIndex={12}
                onClick={async () => {
                    if (isCancellingMode) {
                        setIsCancelModalOpen(true);
                        return;
                    }
                    if (await validateNewAppointment()) {
                        if (await shouldOpenRescheduleChargeModal()) {
                            setIsRescheduleChargeModalOpen(true);
                            return;
                        } else {
                            if (
                                Moment()
                                    .tz(process.env.REACT_APP_TIMEZONE || 'Europe/London')
                                    .isAfter(form.event.start)
                            ) {
                                setIsAppointmentInPastModalOpen(true);
                                return;
                            }
                        }

                        onConfirm(form).then(appt => {
                            if (!appt || !appt.repeatsLastOccurrence) return;
                            const sameDay = appt.repeatsLastOccurrence.isSame(appt.event.start, 'day');
                            dispatch(setDrawerData({ leftDrawerFormChanged: false }));
                            if (sameDay) return;
                        });
                    }
                }}
            >
                <Typography className={classes.regularButtonText}>Save</Typography>
            </Button>
        );
    }, [
        classes.regularButton,
        classes.regularButtonText,
        classes.saveButton,
        dispatch,
        form,
        isCancellingMode,
        onConfirm,
        shouldOpenRescheduleChargeModal,
        validateNewAppointment
    ]);

    const renderConfirmCancelButton = () => (
        <Button
            className={classNames(classes.regularButton, classes.saveButton)}
            onClick={async () => {
                if (!cancelingReason) {
                    toastr.error('Missing field', 'Reason must be completed', { timeOut: 1800 });
                } else {
                    if (isCancellingMode) {
                        setIsCancelModalOpen(true);
                        return;
                    }
                    if (await validateNewAppointment()) onConfirm(form);
                }
            }}
            disabled={postingData}
        >
            {/* *Pink Cancel appt button* */}
            <Typography className={classes.regularButtonText}>Cancel appt</Typography>
        </Button>
    );

    const getRescheduleChargeModalText = () => {
        let message = late_cancellation_template;
        if (lateCancellationText) {
            message = lateCancellationText;
        }

        const tagValues = {
            '{LateCancellationType}': lateCancellationType === 'AMOUNT' ? getCurrencySymbol() : '%',
            '{LateCancellationValue}': lateCancellationChargeValue,
            '{LateCancellationFee}':
                lateCancellationType === 'AMOUNT'
                    ? `${getCurrencySymbol()}${lateCancellationChargeValue}`
                    : `${lateCancellationChargeValue}%`,
            '{LateCancellationPeriod}': lateCancellationPeriod
        };

        [...TC_MERGE_TAGS, ...TC_MODAL_MERGE_TAGS].forEach(tag => {
            while (message.search(tag) > -1) {
                message = message.replace(tag, tagValues[tag]);
            }
        });

        return message;
    };

    setTimeout(() => {
        const element = document.getElementById('form-buttons');

        if (
            !(
                element &&
                element.style.display !== 'none' &&
                element.style.visibility !== 'hidden' &&
                element.clientHeight > 30
            )
        ) {
            console.log('rerender because of no buttons');
            setRenderCount(prev => prev + 1);
        }
    }, 100);

    return (
        renderCount && (
            <>
                <DrawerHeader
                    form={form}
                    isCancellingMode={isCancellingMode}
                    handleChange={handleChange}
                    onClose={onClose}
                    viewMode={viewMode}
                />
                <div className={classes.content + ` renderCount${renderCount}`} ref={apptPanelRef} id="appt-form">
                    <AppointmentForm
                        isCancellingMode={isCancellingMode}
                        clinicStartHour={clinicStartHour}
                        clinicEndHour={clinicEndHour}
                        form={form}
                        handleChange={handleChange}
                        handleChangeNotes={handleChangeNotes}
                        searchCustomers={searchCustomers}
                        showCreateCustomerModal={showCreateCustomerModal}
                        selectLastAppointment={selectLastAppointment}
                        selectLastAppointmentLinked={selectLastAppointmentLinked}
                        clinicStartTime={clinicStartTime}
                        clinicEndTime={clinicEndTime}
                        totalPrice={null}
                        onChangeCustomer={onChangeCustomer}
                        changeDuration={event => handleDurationChange(event, null)}
                        changeStart={event => handleStartChange(event, null)}
                        handleTrownInvalidDateWarning={handleTrownInvalidDateWarning}
                        appointmentLhdIndex={0}
                        validateCreditCard={validateCreditCard}
                    />
                    {renderLinkedAppts()}
                    <CancelAppointment
                        key={form.id}
                        isCancellingMode={isCancellingMode}
                        isCancelModalOpen={isCancelModalOpen}
                        appointment={form}
                        cancelAppointments={cancelAppointments}
                        cancelModal={cancelModal}
                        selectedDay={selectedDay}
                    />

                    {renderClipboard()}

                    {cancellationChargeModal}
                    {isAppointmentInPastModalOpen && (
                        <CancelContinueModal
                            title={'Appointment is in the past'}
                            contentText="The start time of the appointment you are booking has a start time that is in the past. Are you sure you wish to continue?"
                            onContinue={() => {
                                onConfirm(form).then(appt => {
                                    if (!appt || !appt.repeatsLastOccurrence) return;
                                    const sameDay = appt.repeatsLastOccurrence.isSame(appt.event.start, 'day') || false;
                                    if (sameDay) return;
                                });
                            }}
                            setOpen={setIsAppointmentInPastModalOpen}
                        />
                    )}
                    {isDepositModalOpen && (
                        <CancelContinueModal
                            title={`${
                                depositAmount > form.customer?.accountBalance
                                    ? 'Deposit required'
                                    : 'Deposit may not be required'
                            }`}
                            contentText="treść"
                            onContinue={() => {
                                setIsDepositModalOpen(false);
                            }}
                            cancelButton={false}
                            setOpen={setIsAppointmentInPastModalOpen}
                        >
                            <div style={{ display: 'block', minWidth: 300, fontSize: 14 }}>
                                <div>
                                    Client has{' '}
                                    <NumberFormat
                                        value={form.customer?.accountBalance}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'£'}
                                    />{' '}
                                    on account.
                                </div>
                                {depositItems.map((item, index) => {
                                    return (
                                        <div key={`deposit-item-${index}`}>
                                            {item.name} requires deposit of{' '}
                                            <NumberFormat
                                                value={item.value}
                                                fixedDecimalScale
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'£'}
                                            />
                                        </div>
                                    );
                                })}
                                <br />
                                <b>
                                    {depositAmount > form.customer?.accountBalance && (
                                        <>
                                            Total deposit required{' '}
                                            <NumberFormat
                                                value={depositAmount}
                                                fixedDecimalScale
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'£'}
                                            />
                                        </>
                                    )}
                                    {depositAmount <= form.customer?.accountBalance && (
                                        <b>Account total is greater than deposit</b>
                                    )}
                                </b>
                            </div>
                        </CancelContinueModal>
                    )}
                    {isRescheduleChargeModalOpen && (
                        <CancelContinueModal
                            title={'Late appointment reschedule'}
                            onContinue={() => {
                                if (
                                    Moment()
                                        .tz(process.env.REACT_APP_TIMEZONE || 'Europe/London')
                                        .isAfter(form.event.start)
                                ) {
                                    setIsAppointmentInPastModalOpen(true);
                                    return;
                                }

                                onConfirm(form).then(appt => {
                                    if (!appt || !appt.repeatsLastOccurrence) return;
                                    const sameDay = appt.repeatsLastOccurrence.isSame(appt.event.start, 'day');
                                    dispatch(setDrawerData({ leftDrawerFormChanged: false }));
                                    if (sameDay) return;
                                });
                            }}
                            setOpen={setIsRescheduleChargeModalOpen}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getRescheduleChargeModalText()) }}
                            />
                        </CancelContinueModal>
                    )}
                    {isRescheduleConfirmationModalOpen && (
                        <AppointmentConfirmActionModal
                            isOpen={isRescheduleConfirmationModalOpen}
                            setIsOpen={setIsRescheduleConfirmationModalOpen}
                            actionOnContinue={() => {
                                if (editingAppointment) {
                                    dispatch(
                                        setDrawerData({
                                            appointmentsOnClipboard: [form],
                                            isClipboardVisible: true,
                                            savedAppointment: cloneDeep(editingAppointment)
                                        })
                                    );
                                }
                            }}
                            lateCancellationCharge={lateCancellationChargeValue}
                            lateCancellationPeriod={lateCancellationPeriod}
                            appointmentStartTime={form.event.start}
                            type="Reschedule"
                        />
                    )}
                    <footer className={classes.footer + ` renderCount${renderCount}`} id="form-buttons">
                        <div>
                            {!isCancellingMode && !isClipboardVisible && renderCancelOrCloseButton}
                            {!isCancellingMode && form.id && isClipboardVisible && renderCancelOrCloseButton}
                            {!isCancellingMode && form.id && !isClipboardVisible && renderCancelButton}
                            {!isCancellingMode &&
                                !form.checkedIn &&
                                form.id &&
                                !isClipboardVisible &&
                                renderRescheduleButton}
                        </div>
                        <div>
                            {(isCancellingMode || isClipboardVisible) && renderBackButton}
                            {!isCancellingMode && renderSaveButton}
                            {isCancellingMode && !isClipboardVisible && renderConfirmCancelButton()}
                        </div>
                    </footer>
                </div>
            </>
        )
    );
}

AppointmentContent.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleChangeNotes: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    postingData: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    searchCustomers: PropTypes.func.isRequired,
    clinicStartHour: PropTypes.number.isRequired,
    clinicEndHour: PropTypes.number.isRequired,
    clinicStartTime: PropTypes.any.isRequired,
    clinicEndTime: PropTypes.any.isRequired,
    showCreateCustomerModal: PropTypes.func,
    validateNewAppointment: PropTypes.func,
    selectedDay: PropTypes.any,
    setSelectedDay: PropTypes.func,
    handleTrownInvalidDateWarning: PropTypes.func,
    validateCreditCard: PropTypes.func,
    isDataLostModalOpen: PropTypes.bool.isRequired,
    setDataLostModalOpen: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(AppointmentContent), defaultRenderCheck);
