import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const FieldLabel = ({ children, className }) => (
    <Grid lg={5} className={className}>
        {children}
    </Grid>
);

FieldLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export { FieldLabel };
