import Api from './config';

class InvoiceApi {
    static getInvoice(id, withRelated = false, withLogs = false) {
        return Api.request({
            method: 'GET',
            url: `/invoices/${id}?showCustomer=true${withRelated ? '&withRelated=true' : ''}${
                withLogs ? '&withLogs=true' : ''
            }`
        });
    }

    static sendEmailInvoice(id) {
        return Api.request({
            method: 'POST',
            url: `/invoices/${id}/email`,
            data: {}
        });
    }

    static getAllInvoices(customer) {
        return Api.request({
            method: 'GET',
            url: `/invoices?customer=${customer}`
        });
    }

    static loadInvoiceMetaData(customer) {
        return Api.request({
            method: 'GET',
            url: `/invoices/meta-data?customer=${customer}`
        });
    }

    static updateInvoiceStatus(invoiceId, paymentStatus, closedBy) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update-status/${invoiceId}`,
            data: {
                paymentStatus,
                closedBy
            }
        });
    }

    static invoiceRefund(invoiceId, data) {
        return Api.request({
            method: 'POST',
            url: `/invoice/${invoiceId}/refund`,
            data
        });
    }

    static updateInvoice(invoiceId, data) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update/${invoiceId}`,
            data
        });
    }

    static updateInvoiceItems(invoiceId, data) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update-items/${invoiceId}`,
            data
        });
    }

    static updateInvoiceCC(invoiceId, data) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/update-cc/${invoiceId}`,
            data
        });
    }

    static voidOutstandingAmount(invoiceId) {
        return Api.request({
            method: 'PUT',
            url: `/invoice/voidOsAmount/${invoiceId}`
        });
    }

    static checkUnpaidPaymentLinks(invoiceId) {
        return Api.request({
            method: 'GET',
            url: `/invoice/${invoiceId}/has-unpaid-payment-links`
        });
    }

    static createDirectDebit(invoiceId, data) {
        return Api.request({
            method: 'POST',
            url: `invoices/create-direct-debit/${invoiceId}`,
            data
        })
            .then(res => {
                if (res.success) {
                    return { data: res, success: true };
                } else {
                    return {
                        success: false,
                        message: 'Membership could not be created. Please contact Collums Support'
                    };
                }
            })
            .catch(err => {
                if (Array.isArray(err?.response?.data?.validation?.keys)) {
                    const mapper = {
                        firstPayment: 'Date of first payment',
                        amount: 'Monthly amount',
                        numberOfPayments: 'No of monthly payments'
                    };

                    const invalidFields = err?.response?.data?.validation?.keys.map(field => mapper[field]);

                    let message = `Field${invalidFields.length === 1 ? '' : 's'} ${invalidFields.join(
                        ','
                    )} must contain proper value`;
                    return { success: false, message };
                }
            });
    }
}

export default InvoiceApi;
