const tableHeaders = [
    {
        id: 'status',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'createdAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'DD Form sent'
    },
    {
        id: 'formCompletedAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Form Completed'
    },
    {
        id: 'name',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Membership'
    },
    {
        id: 'invoice',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'DD Invoice'
    },
    {
        id: 'amount',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Payment Value'
    },
    {
        id: 'numberOfPayments',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Number Of Payments'
    },
    {
        id: 'firstPayment',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'First Payment'
    },
    {
        id: 'latestPayedAt',
        numeric: false,
        reordable: true,
        disablePadding: false,
        label: 'Latest payment'
    },
    {
        id: 'paymentsTaken',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Payments Taken'
    },
    {
        id: 'paymentsLeft',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Payments Left'
    },
    {
        id: 'amountPaid',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Amount Paid'
    },
    {
        id: 'amountOutstanding',
        numeric: true,
        reordable: true,
        disablePadding: false,
        label: 'Amount OS'
    }
    // {
    //     id: 'soldBy.displayName',
    //     numeric: false,
    //     reordable: true,
    //     disablePadding: false,
    //     label: 'Sold By'
    // }
];

export default tableHeaders;
