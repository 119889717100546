import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const FieldContainer = ({ children }) => (
    <Grid container item alignItems="center" style={{ marginBottom: 10 }}>
        {children}
    </Grid>
);

FieldContainer.propTypes = {
    children: PropTypes.node
};

export { FieldContainer };
